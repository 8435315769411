export interface IRegistrationLinkValidationResponse {
  accountCountry?: string;
  userInfo: UserInfo;
  languageTypes: Languages[];
  dateTypes: DateFormat[];
  timezoneTypes: TimeZones[];
}

export class UserInfo {
  public UserKey: number;
  public UserName: string;
  public FirstName: string;
  public LastName: string;
  public Phone: string;
  public PhoneCountry: string;
  public PhoneExtension: string;
  public AccountKey: string;
}
export class Languages {
  public languageCode: string;
  public languageDescription: string;
}
export class TimeZones {
  public timeZoneCode: string;
  public timeZoneDescription: string;
}
export class DateFormat {
  public dateTypeKey: number;
  public description: string;
}
export class RegistrationToken {
  public token: string;
}

export class UpdateUserResponse {
  public status: string;
}
export class UpdateUserRequest {
  public userName: string;
  public userKey: number;
  public languageCode: string;
  public password: string;
  public accountKey: number;
  public phoneCountry: string;
  public phone: string;
  public phoneExtension: string;
  public timeZoneCode: string;
  public firstName: string;
  public lastName: string;
  public dateType: number;
}

export interface IConfigCountry {
  countryCode: string;
  countryName: string;
  languageCode: string;
  isVisible: true;
  addedOn: string;
  addedBy: number;
  modifiedOn: string;
  modifiedBy: number;
}
