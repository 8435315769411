import { toolKeys } from '@lbmx/types';

export const routePermissions = {
  PIMModule: toolKeys.PIM,
  userProfile: toolKeys.AUTHENTICATED,
  login: toolKeys.AUTHENTICATED,
  logout: toolKeys.AUTHENTICATED,
  AdministrationModule: toolKeys.ADMINISTRATION,
  PIMModule_products: toolKeys.PIM_PRODUCTS,
  PIMModule_exports: toolKeys.AUTHENTICATED,
  'PIMModule_exports_view-exports': toolKeys.AUTHENTICATED,
  PIMModule_imports: toolKeys.PIM_IMPORT,
  'PIMModule_products_approved-products': toolKeys.PIM_PRODUCTS,
  'PIMModule_products_product-fields': toolKeys.PIM_PRODUCTS,
  'PIMModule_products_inbound-update-profile':
    toolKeys.PIM_INBOUND_UPDATE_PROFILE,
  'PIMModule_products_products-pending': toolKeys.PIM_PENDING_PRODUCTS,
  'PIMModule_products_product-details': toolKeys.PIM_PRODUCTS,
  'PIMModule_products_outbound-update-profile':
    toolKeys.PIM_OUTBOUND_UPDATE_PROFILES,
  'PIMModule_products_outbound-update-profile_profile':
    toolKeys.PIM_OUTBOUND_UPDATE_PROFILES,
  'PIMModule_products_outbound-update-profile_profile-edit':
    toolKeys.PIM_OUTBOUND_UPDATE_PROFILES,
  'PIMModule_products_outbound-update-profile_profile-copy':
    toolKeys.PIM_OUTBOUND_UPDATE_PROFILES,
  'PIMModule_products_outbound-update-profile_partner-selection':
    toolKeys.PIM_OUTBOUND_UPDATE_PROFILES,
  'PIMModule_products_outbound-update-profile_partner-selection-edit':
    toolKeys.PIM_OUTBOUND_UPDATE_PROFILES,
  'PIMModule_products_outbound-update-profile_partner-selection-copy':
    toolKeys.PIM_OUTBOUND_UPDATE_PROFILES,
  'PIMModule_products_outbound-update-profile_hierarchy-selection':
    toolKeys.PIM_OUTBOUND_UPDATE_PROFILES,
  'PIMModule_products_outbound-update-profile_hierarchy-selection-edit':
    toolKeys.PIM_OUTBOUND_UPDATE_PROFILES,
  'PIMModule_products_outbound-update-profile_hierarchy-selection-copy':
    toolKeys.PIM_OUTBOUND_UPDATE_PROFILES,
  'PIMModule_products_outbound-update-profile_category-selection':
    toolKeys.PIM_OUTBOUND_UPDATE_PROFILES,
  'PIMModule_products_outbound-update-profile_category-selection-edit':
    toolKeys.PIM_OUTBOUND_UPDATE_PROFILES,
  'PIMModule_products_outbound-update-profile_category-selection-copy':
    toolKeys.PIM_OUTBOUND_UPDATE_PROFILES,
  'PIMModule_imports_import-assets': toolKeys.PIM,
  'PIMModule_imports_import-products': toolKeys.PIM_IMPORT_LAYOUTS,
  PIMModule_analytics: toolKeys.PIM,
  'PIMModule_analytics_product-fields': toolKeys.PIM,
  'PIMModule_imports_import-products_select-file': toolKeys.PIM_IMPORT_LAYOUTS,
  'PIMModule_imports_import-products_column-wizard':
    toolKeys.PIM_IMPORT_LAYOUTS,
  'PIMModule_imports_import-products_layout-manager':
    toolKeys.PIM_IMPORT_LAYOUTS,
  'PIMModule_imports_import-products_email-notify': toolKeys.PIM_IMPORT_LAYOUTS,
  'PIMModule_imports_import-products_finish': toolKeys.PIM_IMPORT_LAYOUTS,
  MarketplacePartnersModule: toolKeys.MPP,
  MarketplacePartnersModule_my_marketplace: toolKeys.MPP_GROUP,
  MarketplacePartnersModule_group_new: toolKeys.MPP_GROUP_MAINTENANCE,
  MarketplacePartnersModule_group_edit: toolKeys.MPP_GROUP_MAINTENANCE,
  MarketplacePartnersModule_member: toolKeys.MPP_MEMBER,
  MarketplacePartnersModule_member_new: toolKeys.MPP_MEMBER_MAINTENANCE,
  MarketplacePartnersModule_member_edit: toolKeys.MPP_MEMBER_MAINTENANCE,
  MarketplacePartnersModule_supplier: toolKeys.MPP_SUPPLIER,
  MarketplacePartnersModule_supplier_new: toolKeys.MPP_SUPPLIER_MAINTENANCE,
  MarketplacePartnersModule_supplier_edit: toolKeys.MPP_SUPPLIER_MAINTENANCE,
  MarketplacePartnersModule_company: toolKeys.MPP_SUPPLIER,
  MarketplacePartnersModule_company_edit: toolKeys.MPP_SUPPLIER,
  MarketplacePartnersModule_requests: toolKeys.MPP_REQUEST,
  MarketplaceModule: toolKeys.MP,
  MarketplaceModule_invoices: toolKeys.MP_INVOICE,
  MarketplaceModule_purchase_order: toolKeys.MP_PO,
  MarketplaceModule_poas_received: toolKeys.MP_POA,
  MarketplaceModule_asns_received: toolKeys.MP_ASN,
  MarketplaceModule_claims_received: toolKeys.MP_CLAIMS_RECEIVED,
  MarketplaceModule_remittances_received: toolKeys.MP_REMITTANCES_RECEIVED,
};
