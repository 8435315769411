import { enableProdMode, ReflectiveInjector } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { EnvService } from '../src/app/services/env.service';
import { AppModule } from './app/app.module';

// tslint:disable-next-line: deprecation
const injector = ReflectiveInjector.resolveAndCreate([EnvService]);
const envService: EnvService = injector.get(EnvService);

const isProduction = (envService.Prod === 'true');

if (isProduction) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
