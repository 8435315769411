import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { selectors } from '@lbmx/app-state';

import { AuthenticateService, UserAuthRequest } from '@lbmx/phoenix-lib-auth';
import { SpinnerService } from '@lbmx/phoenix-lib-utils';
import { UserState } from '@lbmx/types';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfigProvider } from 'src/app/provider/config-provider';
@Component({
  selector: 'app-user-login-component',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  //#region Properties
  private navigateToUrl: string;
  private rememberMe: boolean;
  public isCredentialInvalid = false; // used in the html component
  public subscription: Subscription;

  public loginForm: FormGroup = this.formBuilder.group({
    username: [
      '',
      [Validators.required, Validators.email, Validators.maxLength(100)],
    ],
    password: ['', [Validators.required]],
    checked: [''],
  });
  //#endregion
  constructor(
    private authSrv: AuthenticateService,
    private spinnerSrv: SpinnerService,
    private formBuilder: FormBuilder,
    private configProvider: ConfigProvider,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store
  ) {}
  public subscriptions: Subscription[] = [];
  public async ngOnInit() {
    this.navigateToUrl =
      this.route.snapshot.queryParams['nextUrl'] || '/default';

    this.subscriptions.push(
      this.store
        .pipe(
          select(selectors.userFeature),
          map((userState: UserState) => {
            if (new Date(userState.tokenExpires) > new Date()) {
              this.router.navigate([this.navigateToUrl]);
            }
          })
        )
        .subscribe()
    );

    const cookieStatus = await this.authSrv.getCookieRememberMe().toPromise();

    this.rememberMe = cookieStatus.rememberMeStatus;

    if (this.rememberMe) {
      this.loginForm.controls['checked'].setValue(true);

      const cookieUser = await this.authSrv.getCookieUserName().toPromise();
      this.loginForm.controls['username'].setValue(cookieUser.userName);
    }

    if (this.rememberMe == null) {
      this.rememberMe =
        this.loginForm.value['checked'].value === null
          ? false
          : this.loginForm.value['checked'];
    }

    this.spinnerSrv.off();
    this.subscription = this.loginForm
      .get('password')
      .valueChanges.subscribe((password) => {
        if (password !== this.loginForm.value.password) {
          this.isCredentialInvalid = false;
        }
      });
  }

  public ngOnDestroy() {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }
  //#region Methods

  public authenticate(): void {
    this.spinnerSrv.on();
    this.authSrv.authenticate(
      this.buildLoginRequest(),
      this.configProvider.AppSetting,
      () => {
        this.router.navigate([this.navigateToUrl]);
        this.spinnerSrv.off();
      },
      (err) => {
        if (err.status === 401) {
          this.isCredentialInvalid = true;
          this.spinnerSrv.off();
        }
      }
    );
  }

  public checkBoxStatus(): void {
    this.rememberMe = this.loginForm.value['checked'];
  }

  //#region Helpers

  private buildLoginRequest(): UserAuthRequest {
    const request: UserAuthRequest = new UserAuthRequest();
    request.username = this.loginForm.value['username'].trim();
    request.password = this.loginForm.value['password'].trim();
    request.rememberMe = this.rememberMe;

    return request;
  }
  //#endregion
}
