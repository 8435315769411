import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SecureService {
  private marketplaceSelected: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );

  public marketplaceSelected$: Observable<boolean>;

  public setSelectedMarketplace(isSet: boolean) {
    this.marketplaceSelected.next(isSet);
  }
  // tslint:disable-next-line: no-empty
  constructor() {
    this.marketplaceSelected$ = this.marketplaceSelected.asObservable();
  }
}
