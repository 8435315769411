import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { actions, actionTypes } from '@lbmx/app-state';
import { LoggerService } from '@lbmx/phoenix-lib-core';
import {
  SpinnerService,
  ToastrNotificationService,
} from '@lbmx/phoenix-lib-utils';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as i18nIsoCountries from 'i18n-iso-countries';
import { Observable, of, Subscription } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { IConfigCountry } from 'src/app/dtos/registration/registration.dto';
import { IUserProfileForm } from 'src/app/dtos/userProfile/userProfile.dto';
import { ConfigProvider } from 'src/app/provider/config-provider';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';
@Component({
  selector: 'app-user-profile-container',
  template: `<app-userprofile
    [fetching]="fetching"
    [defaultCountryCode]="defaultCountryCode$ | async"
    [countries]="countries$ | async"
    [userProfileDetails]="userProfileDetails$ | async"
    [timeZoneTypes]="timeZoneCodes"
    [languageTypes]="languageCodes"
    [DTFormat]="dateFormatTypes"
    (updatedProfileInfo)="handleSave($event)"
  ></app-userprofile>`,
  styleUrls: [],
})
export class UserProfileContainerComponent implements OnInit, OnDestroy {
  public userProfileDetails$: Observable<any> = of({
    userDetails: {
      firstName: '',
      lastName: '',
      userName: '',
      phone: '',
      phoneExtension: '',
      phoneCountry: '',
      languageCode: '',
      timeZoneCode: '',
      dateTypeKey: 0,
      isTermsSigned: 0,
    },
  });
  public defaultCountryCode$: Observable<string>;
  public countries$: Observable<any[]>;
  public dateFormatTypes = [];
  public timeZoneCodes = [];
  public languageCodes = [];
  public timeZoneTypes$: Observable<any[]>;
  public fetching = false;
  public subscriptions: Subscription[] = [];

  constructor(
    private loggerService: LoggerService,
    private toastSrv: ToastrNotificationService,
    private spinnerService: SpinnerService,
    private userProfileService: UserProfileService,
    private translocoService: TranslocoService,
    private store: Store,
    private configPrv: ConfigProvider,
    private actions$: Actions,
    private router: Router
  ) {}

  public async ngOnInit() {
    this.spinnerService.on();
    this.fetching = true;
    this.defaultCountryCode$ = this.userProfileService.getAccount().pipe(
      map((account) => account.details.country),
      catchError(() => of('CAN'))
    );
    this.countries$ = this.userProfileService.getCountries().pipe(
      map((countries: IConfigCountry[]) =>
        countries.map((country) => {
          return {
            label: `${country.countryName} (${i18nIsoCountries.alpha3ToAlpha2(
              country.countryCode
            )})`,
            value: i18nIsoCountries.alpha3ToAlpha2(country.countryCode),
          };
        })
      )
    );
    this.userProfileDetails$ = this.userProfileService.getUserProfile().pipe(
      tap((response) => {
        this.loggerService.info(
          'getUserProfileResponse',
          'UserProfileService.getUserProfile',
          JSON.stringify(response)
        );
      }),
      map((response) => {
        return {
          firstName: response.userDetails.firstName,
          lastName: response.userDetails.lastName,
          userName: response.userDetails.username,
          phoneCountry: response.userDetails.phoneCountry,
          phone: response.userDetails.phone,
          phoneExtension: response.userDetails.phoneExtension,
          languageCode: response.userDetails.languageCode,
          timeZoneCode: response.userDetails.timeZoneCode,
          dateTypeKey: response.userDetails.dateTypeKey,
          isTermsSigned: response.userDetails.isTermsSigned,
        };
      }),
      finalize(() => {
        this.fetching = false;
        this.spinnerService.off();
      })
    );

    /* Get list of DateFormat Types */
    this.dateFormatTypes = await this.userProfileService
      .getdateFormatTypes()
      .toPromise()
      .then((res) => {
        if (res.dateFormatTypeResponse != null) {
          return res.dateFormatTypeResponse.map((a) => {
            return { value: a.dateTypeKey, label: a.description };
          });
        }
      });

    /* Get list of Language Types */
    this.languageCodes = await this.userProfileService
      .getLanguageTypes()
      .toPromise()
      .then((res) => {
        if (res.languageTypes != null) {
          return res.languageTypes.map((a) => {
            return { value: a.languageCode, label: a.languageDescription };
          });
        }
      });
    /* Get list of Timezone Types */
    this.timeZoneCodes = await this.userProfileService
      .getTimezoneTypes()
      .toPromise()
      .then((res) => {
        if (res.timeZoneTypeResponse != null) {
          return res.timeZoneTypeResponse.map((a) => {
            return { value: a.timeZoneCode, label: a.timeZoneDescription };
          });
        }
      });
  }

  public ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
    this.spinnerService.off();
  }

  public handleSave(data) {
    this.spinnerService.on();
    /* Eliminate null values before updateProfile endpoint call  */
    const request = this.filterEmptyFields(data) as IUserProfileForm;
    if (request.confirmPassword) {
      delete request.confirmPassword;
    }

    this.store.dispatch(
      actions.updateUserProfileFromForm({
        userProfile: request,
        endPoints: this.configPrv.AppSetting,
      })
    );
    this.subscriptions.push(
      this.actions$
        .pipe(
          ofType(
            actions.updateUserProfileSuccess,
            actions.updateUserProfileFailure
          ),
          map((action) => {
            if (action.type === actionTypes.UPDATE_USER_PROFILE_SUCCESS) {
              this.loggerService.info(
                'UpdateUserResponse',
                'UserProfileService.updateUserProfile'
              );
              this.toastSrv.successNotify(
                this.translocoService.translate('COMMON.CHANGES_SAVED')
              );
              this.translocoService.setActiveLang(request.languageCode);
              if (request['password']) {
                this.router.navigate(['/logout']);
              }
            } else {
              this.loggerService.error(
                'UpdateUserResponse',
                'UserProfileService.updateUserProfile'
              );
            }
            this.spinnerService.off();
          })
        )
        .subscribe()
    );
  }
  /** Function to filter null values from userProfileForm */
  private filterEmptyFields(data) {
    return Object.entries(data).reduce((prev, [field, value]) => {
      if (value !== '' && value !== null) {
        return { ...prev, [field]: value };
      } else {
        return prev;
      }
    }, {});
  }
}
