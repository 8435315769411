// Angular
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER } from '@angular/core';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import {
  Compiler,
  COMPILER_OPTIONS,
  CompilerFactory,
  LOCALE_ID,
  NgModule,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, Title } from '@angular/platform-browser';
import { JitCompilerFactory } from '@angular/platform-browser-dynamic';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OnMouseMoveDirective } from './layouts/secure/on-mouse-move.directive';
// Services
import { AuthGuardService } from './services/authGuard/authGuard.service';

// App
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Kendo
import {
  CldrIntlService,
  IntlModule,
  IntlService,
} from '@progress/kendo-angular-intl';
import '@progress/kendo-angular-intl/locales/en-AU/all';
import '@progress/kendo-angular-intl/locales/en-AU/calendar';
import '@progress/kendo-angular-intl/locales/en-CA/all';
import '@progress/kendo-angular-intl/locales/en-gb/all';
import '@progress/kendo-angular-intl/locales/en-gb/calendar';
import '@progress/kendo-angular-intl/locales/en-NZ/all';
import '@progress/kendo-angular-intl/locales/en/all';
import '@progress/kendo-angular-intl/locales/en/calendar';

import '@progress/kendo-angular-intl/locales/es/all';
import '@progress/kendo-angular-intl/locales/fr/all';
import '@progress/kendo-angular-intl/locales/ru/all';

// Transloco
import {
  TRANSLOCO_CONFIG,
  TranslocoConfig,
  TranslocoModule,
} from '@ngneat/transloco';
import { TranslocoMessageFormatModule } from '@ngneat/transloco-messageformat';

// UI Modules - Prime
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { MegaMenuModule } from 'primeng/megamenu';
import { MenubarModule } from 'primeng/menubar';
import { PasswordModule } from 'primeng/password';
import { ToastModule } from 'primeng/toast';
import { TreeTableModule } from 'primeng/treetable';

// Public Component
import { LoginComponent } from './../app/features/public/login/login.component';
import { RecoverPasswordComponent } from './features/public/recover-password/recover-password.component';
import { ResetPasswordComponent } from './features/public/reset-password/reset-password.component';

// Secure Component
import { HomeComponent } from './../app/features/secure/home/home.component';
import { BreadcrumbModule } from './features/secure/breadcrumb/breadcrumb.module';
import { MainFooterComponent } from './features/secure/main-footer/main-footer.component';
import { MainHeaderComponent } from './features/secure/main-header/main-header.component';
import { MainNavigationContainerComponent } from './features/secure/main-navigation-container/main-navigation-container.component';
import { ScrollTopComponent } from './features/secure/main-scroll-top/main-scroll-top.component';

// Packages
import { AppStateModule } from '@lbmx/app-state';
import { AuthModule } from '@lbmx/phoenix-lib-auth';
import { CoreModule } from '@lbmx/phoenix-lib-core';
import { UtilsModule } from '@lbmx/phoenix-lib-utils';

// Environments and loaders
import { DialogModule } from 'primeng/dialog';
import { httpLoader } from 'src/loaders/http.loader';
import { LogoutComponent } from './features/public/logout/logout.component';
import { RegisterComponent } from './features/public/register/register.component';
import { BreadcrumbService } from './features/secure/breadcrumb/breadcrumb.service';
import { ErrorComponent } from './features/secure/error/error.component';
import { MainModuleSelectorComponent } from './features/secure/main-navigation-container/main-module-selector/main-module-selector.component';
import { MainNavBarComponent } from './features/secure/main-navigation-container/main-nav-bar/main-nav-bar.component';
import { TermsServicesComponent } from './features/secure/terms-services/terms-services.component';
import { UserProfileContainerComponent } from './features/secure/userprofile/user-profile-container';
import { UserprofileComponent } from './features/secure/userprofile/userprofile.component';
import { PublicComponent } from './layouts/public/public.component';
import { MarketplaceModalComponent } from './layouts/secure/marketplace-modal.component';
import { SecureComponent } from './layouts/secure/secure.component';
import { ConfigProvider } from './provider/config-provider';
import { EnvServiceProvider } from './services/EnvServiceProvider';
import { RequestInterceptor } from './services/error/http.interceptor';

export function createCompiler(compilerFactory: CompilerFactory) {
  return compilerFactory.createCompiler();
}

export function configProviderFactory(provider: ConfigProvider) {
  return () => provider.load();
}

@NgModule({
  imports: [
    AppStateModule,
    BrowserModule,
    BreadcrumbModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    InputTextModule,
    ButtonModule,
    PasswordModule,
    CheckboxModule,
    DropdownModule,
    CardModule,
    MegaMenuModule,
    MenubarModule,
    CoreModule,
    UtilsModule,
    AuthModule,
    TranslocoModule,
    DialogModule,

    IntlModule,
    ToastModule,
    TreeTableModule,
    TranslocoMessageFormatModule.init(),
  ],
  declarations: [
    OnMouseMoveDirective,
    AppComponent,
    UserProfileContainerComponent,
    PublicComponent,
    LoginComponent,
    RecoverPasswordComponent,
    ResetPasswordComponent,
    SecureComponent,
    HomeComponent,
    MainHeaderComponent,
    MainNavigationContainerComponent,
    MainFooterComponent,
    ScrollTopComponent,
    RegisterComponent,
    MainNavBarComponent,
    MainModuleSelectorComponent,
    UserprofileComponent,
    LogoutComponent,
    TermsServicesComponent,
    MarketplaceModalComponent,
    ErrorComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    httpLoader,
    {
      provide: TRANSLOCO_CONFIG,
      useValue: {
        listenToLangChange: true,
        reRenderOnLangChange: true,
        defaultLang: 'en-ca',
        fallbackLang: 'en-ca',
        useFallbackTranslation: true,
        scopeStrategy: 'shared',
      } as TranslocoConfig,
    },
    AuthGuardService,
    BreadcrumbService,
    EnvServiceProvider,
    ConfigProvider,
    {
      provide: COMPILER_OPTIONS,
      useValue: {},
      multi: true,
    },
    {
      provide: CompilerFactory,
      useClass: JitCompilerFactory,
      deps: [COMPILER_OPTIONS],
    },
    {
      provide: Compiler,
      useFactory: createCompiler,
      deps: [CompilerFactory],
    },
    CldrIntlService,
    {
      provide: IntlService,
      useExisting: CldrIntlService,
    },
    {
      provide: LOCALE_ID,
      useValue: 'en-ca',
    },
    {
      provide: APP_INITIALIZER,
      useFactory: configProviderFactory,
      deps: [ConfigProvider],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true,
    },
    Title,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
