import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { IBreadcrumb } from './types/breadcrumb';
import {
  BreadcrumbFunction,
  IBreadcrumbObject,
} from './types/breadcrumb.config';

type BreadcrumbConfig = IBreadcrumbObject | BreadcrumbFunction | string;
export type BreadcrumbDefinition = IBreadcrumb & IBreadcrumbObject;
const PATH_PARAM = {
  PREFIX: ':',
  REGEX_IDENTIFIER: '/:[^/]+',
  REGEX_REPLACER: '/[^/]+',
};
const isNonEmpty = (obj: unknown): boolean => {
  return obj && Object.keys(obj).length > 0;
};

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService implements OnDestroy {
  private baseHref = '/';
  public subscriptions: Subscription[] = [];
  /**
   * breadcrumbList for the current route
   * When breadcrumb info is changed dynamically, check if the currentBreadcrumbs is effected
   * If effected, update the change and emit a new stream
   */
  private currentBreadcrumbs: BreadcrumbDefinition[] = [];
  private previousBreadcrumbs: BreadcrumbDefinition[] = [];

  /**
   * Breadcrumbs observable to be subscribed by BreadcrumbComponent
   * Emits on every route change
   */
  private breadcrumbs = new BehaviorSubject<BreadcrumbDefinition[]>([]);
  public breadcrumbs$ = this.breadcrumbs.asObservable();

  constructor(private activatedRoute: ActivatedRoute, private router: Router) {
    this.detectRouteChanges();
  }

  public ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  /**
   * Whenever route changes build breadcrumb list again
   */
  private detectRouteChanges() {
    this.subscriptions.push(
      this.router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe(() => {
          this.previousBreadcrumbs = this.currentBreadcrumbs;
          // breadcrumb label for base OR root path.
          const rootBreadcrumb = this.getRootBreadcrumb();
          this.currentBreadcrumbs = rootBreadcrumb ? [rootBreadcrumb] : [];
          this.prepareBreadcrumbList(this.activatedRoute.root, this.baseHref);
        })
    );
  }

  private getRootBreadcrumb() {
    const rootConfig = this.router.config.find((config) => config.path === '');
    let rootBreadcrumb = this.extractObject({});
    if (rootConfig && rootConfig.data && rootConfig.data.breadcrumb) {
      rootBreadcrumb = this.extractObject(rootConfig.data.breadcrumb);
    }

    if (isNonEmpty(rootBreadcrumb)) {
      return {
        ...rootBreadcrumb,
        routeLink: this.baseHref,
        ...this.getQueryParamsFromPreviousList('/'),
      };
    }
  }

  private prepareBreadcrumbItem(
    activatedRoute: ActivatedRoute,
    routeLinkPrefix: string
  ): BreadcrumbDefinition {
    const { path, breadcrumb } = this.parseRouteData(
      activatedRoute.routeConfig
    );
    const resolvedSegment = this.resolvePathSegment(path, activatedRoute);
    const routeLink = `${routeLinkPrefix}${resolvedSegment}`;
    const label = this.extractLabel(
      breadcrumb && breadcrumb.label ? breadcrumb.label : {},
      resolvedSegment
    );
    let isAutoGeneratedLabel = false;
    let autoGeneratedLabel = '';
    if (!label) {
      isAutoGeneratedLabel = true;
      autoGeneratedLabel = resolvedSegment;
    }

    return {
      ...breadcrumb,
      label: isAutoGeneratedLabel ? autoGeneratedLabel : label,
      routeLink,
      isAutoGeneratedLabel,
      ...this.getQueryParamsFromPreviousList(routeLink),
    };
  }

  private prepareBreadcrumbList(
    activatedRoute: ActivatedRoute,
    routeLinkPrefix: string
  ): IBreadcrumb[] {
    if (activatedRoute.routeConfig && activatedRoute.routeConfig.path) {
      const breadcrumbItem = this.prepareBreadcrumbItem(
        activatedRoute,
        routeLinkPrefix
      );
      this.currentBreadcrumbs.push(breadcrumbItem);

      if (activatedRoute.firstChild) {
        return this.prepareBreadcrumbList(
          activatedRoute.firstChild,
          breadcrumbItem.routeLink + '/'
        );
      }
    } else if (activatedRoute.firstChild) {
      return this.prepareBreadcrumbList(
        activatedRoute.firstChild,
        routeLinkPrefix
      );
    }
    const lastCrumb = this.currentBreadcrumbs[
      this.currentBreadcrumbs.length - 1
    ];
    this.setQueryParamsForActiveBreadcrumb(lastCrumb, activatedRoute);

    // remove breadcrumb items that needs to be hidden
    const breadcrumbsToShow = this.currentBreadcrumbs.filter(
      (item) => !item.skip
    );

    this.breadcrumbs.next(breadcrumbsToShow);
  }

  /**
   * if the path segment has route params, read the param value from url
   * for each segment of route this gets called
   *
   * for mentor/:id/view - it gets called with mentor, :id, view 3 times
   */
  private resolvePathSegment(segment: string, activatedRoute: ActivatedRoute) {
    // quirk -segment can be defined as view/:id in route config in which case you need to make it view/<resolved-param>
    if (segment.includes(PATH_PARAM.PREFIX)) {
      Object.entries(activatedRoute.snapshot.params).forEach(([key, value]) => {
        segment = segment.replace(`:${key}`, `${value}`);
      });
    }
    return segment;
  }

  /**
   * queryParams & fragments for previous breadcrumb path are copied over to new list
   */
  private getQueryParamsFromPreviousList(routeLink: string): IBreadcrumb {
    const breadcrumbItem =
      this.previousBreadcrumbs.find((item) => item.routeLink === routeLink) ||
      {};
    const { queryParams, fragment } = breadcrumbItem;
    return { queryParams, fragment };
  }

  /**
   * set current activated route query params to the last breadcrumb item
   */
  private setQueryParamsForActiveBreadcrumb(
    lastItem: IBreadcrumb,
    activatedRoute: ActivatedRoute
  ) {
    if (lastItem) {
      const { queryParams, fragment } = activatedRoute.snapshot;
      lastItem.queryParams = queryParams ? { ...queryParams } : undefined;
      lastItem.fragment = fragment;
    }
  }

  /**
   * For a specific route, breadcrumb can be defined either on parent OR it's child(which has empty path)
   * When both are defined, child takes precedence
   *
   * Ex: Below we are setting breadcrumb on both parent and child.
   * So, child takes precedence and "Defined On Child" is displayed for the route 'home'
   * { path: 'home', loadChildren: './home/home.module#HomeModule' , data: {breadcrumb: "Defined On Module"}}
   *                                                AND
   * children: [
   *   { path: '', component: ShowUserComponent, data: {breadcrumb: "Defined On Child" }
   * ]
   */
  private parseRouteData(routeConfig) {
    const { path, data } = routeConfig;
    let breadcrumb = this.mergeWithBaseChildData(routeConfig, {});
    if (data) {
      breadcrumb = this.mergeWithBaseChildData(routeConfig, data.breadcrumb);
    }

    return { path, breadcrumb };
  }

  /**
   * get empty children of a module or Component. Empty child is the one with path: ''
   * When parent and it's children (that has empty route path) define data merge them both with child taking precedence
   */
  private mergeWithBaseChildData(
    routeConfig,
    config: BreadcrumbConfig
  ): IBreadcrumbObject {
    if (!routeConfig) {
      return this.extractObject(config);
    }

    let baseChild;
    if (routeConfig.loadChildren) {
      // To handle a module with empty child route
      baseChild = routeConfig._loadedConfig.routes.find(
        (route) => route.path === ''
      );
    } else if (routeConfig.children) {
      // To handle a component with empty child route
      baseChild = routeConfig.children.find((route) => route.path === '');
    }

    let childConfig: any;
    if (baseChild && baseChild.data) {
      childConfig = baseChild.data.breadcrumb;
    }

    return childConfig
      ? this.mergeWithBaseChildData(baseChild, {
          ...this.extractObject(config),
          ...this.extractObject(childConfig),
        })
      : this.extractObject(config);
  }

  /**
   * In App's RouteConfig, breadcrumb can be defined as a string OR a function OR an object
   *
   * string: simple static breadcrumb label for a path
   * function: callback that gets invoked with resolved path param
   * object: additional data defined along with breadcrumb label that gets passed to *appBreadcrumbItem directive
   */
  private extractLabel(config: BreadcrumbConfig, resolvedParam?: string) {
    const label = typeof config === 'object' ? config.label : config;
    if (typeof label === 'function') {
      return label(resolvedParam);
    }
    return label;
  }

  private extractObject(config: BreadcrumbConfig): IBreadcrumbObject {
    // don't include {label} if config is undefined. This is important since we merge the configs
    if (
      config &&
      (typeof config === 'string' || typeof config === 'function')
    ) {
      return { label: config };
    }
    return (config as IBreadcrumbObject) || {};
  }
}
