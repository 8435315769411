import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseAPIService } from '@lbmx/phoenix-lib-core';
import {
  Translation,
  TRANSLOCO_LOADER,
  TranslocoLoader,
} from '@ngneat/transloco';
import { EnvService } from '../app/services/env.service';
@Injectable()
export class HttpLoader implements TranslocoLoader {
  constructor(
    private http: HttpClient,
    private envSevice: EnvService,
    private baseApiService: BaseAPIService
  ) {}

  public getTranslation(langPath: string) {
    this.baseApiService.set(`/assets/i18n/${langPath}.json`);
    return this.baseApiService.get<Translation>({});
  }
}

export const httpLoader = { provide: TRANSLOCO_LOADER, useClass: HttpLoader };
