import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { actions, selectors } from '@lbmx/app-state';
import { select, Store } from '@ngrx/store';

import { AuthenticateService } from '@lbmx/phoenix-lib-auth';
import { LoggerService } from '@lbmx/phoenix-lib-core';
import { ToastrNotificationService } from '@lbmx/phoenix-lib-utils';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss'],
})
export class MainHeaderComponent implements OnInit, OnDestroy {
  //#region Properties

  public userName = '';
  public companyName = '';
  public subscriptions: Subscription[] = [];

  //#endregion

  constructor(
    private router: Router,
    private toastrNotificationService: ToastrNotificationService,
    private authenticateService: AuthenticateService,
    private loggerService: LoggerService,
    private store: Store
  ) {}

  public ngOnInit() {
    this.subscriptions.push(
      this.store.pipe(select(selectors.userProfile)).subscribe((res) => {
        this.userName = res.userInfo.FullName;
        this.companyName = res.userInfo.CompanyName;
      })
    );
  }

  public ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }
}
