import { Routes } from '@angular/router';
import { ErrorComponent } from 'src/app/features/secure/error/error.component';
import { TermsServicesComponent } from 'src/app/features/secure/terms-services/terms-services.component';
import { AuthGuardService } from 'src/app/services/authGuard/authGuard.service';

import { DefaultRouteService } from 'src/app/services/defaultRoute/default-route.service';
import { HomeComponent } from './../../features/secure/home/home.component';
import { UserProfileContainerComponent } from './../../features/secure/userprofile/user-profile-container';
export const SECURE_ROUTES: Routes = [
  {
    path: 'default',
    canActivate: [DefaultRouteService],
    component: HomeComponent,
  },
  {
    path: 'home',
    data: { breadcrumb: 'BREADCRUMB.HOME' },
    component: HomeComponent,
  },
  {
    path: 'error',
    component: ErrorComponent,
    canActivate: [AuthGuardService],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'userProfile',
    data: { breadcrumb: 'BREADCRUMB.USER_PROFILE' },
    component: UserProfileContainerComponent,
  },
  {
    path: 'PlaygroundModule',
    data: { breadcrumb: 'playgroundModule' },
    loadChildren: () =>
      import('../../wrapper-modules/playground-wrapper.module').then(
        (m) => m.PlaygroundWrapperModule
      ),
  },
  {
    path: 'PIMModule',
    loadChildren: () =>
      import('../../wrapper-modules/pim-wrapper.module').then(
        (m) => m.PimWrapperModule
      ),
  },
  {
    path: 'AdministrationModule',
    loadChildren: () =>
      import('../../wrapper-modules/administration-wrapper.module').then(
        (m) => m.AdministrationWrapperModule
      ),
  },
  {
    path: 'MarketplacePartnersModule',
    data: { breadcrumb: 'BREADCRUMB.PARTNERS' },
    loadChildren: () =>
      import('../../wrapper-modules/marketplace-partners-wrapper.module').then(
        (m) => m.MarketplacePartnersWrapperModule
      ),
  },
  {
    path: 'MarketplaceModule',
    data: { breadcrumb: 'BREADCRUMB.DOCUMENTS' },
    loadChildren: () =>
      import('../../wrapper-modules/marketplace-wrapper.module').then(
        (m) => m.MarketplaceWrapperModule
      ),
  },
];
