/** The session is considered expired if twice the lifetime of the token has elapsed */
export const sessionExpired = (
  tokenIssued: Date,
  tokenExpires: Date
): boolean => {
  return (
    new Date(
      new Date(tokenExpires).valueOf() +
        (new Date(tokenExpires).valueOf() - new Date(tokenIssued).valueOf())
    ) < new Date()
  );
};
