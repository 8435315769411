import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppSetting } from '@lbmx/types';
import { ConfigProvider } from 'src/app/provider/config-provider';

import {
  Languages,
  TimeZones,
} from '@lbmx/administration/lib/dtos/updateuser.dto';
import {
  IAccount,
  IAccountResponse,
} from '@lbmx/marketplace-partners/lib/models/account.models';
import { BaseAPIService, LoggerService } from '@lbmx/phoenix-lib-core';
import {
  IConfigCountry,
  IRegistrationLinkValidationResponse,
  RegistrationToken,
  UpdateUserRequest,
  UpdateUserResponse,
} from 'src/app/dtos/registration/registration.dto';

@Injectable({
  providedIn: 'root',
})
export class RegistrationService {
  constructor(
    private configPrv: ConfigProvider,
    private baseApiService: BaseAPIService,
    private loggerSrv: LoggerService
  ) {}
  public getLanguageTypes(): Observable<any> {
    const appSettings: AppSetting = this.configPrv.AppSetting;
    this.baseApiService.set(appSettings.uriAdministration.languageTypes);
    return this.baseApiService.get<Languages[]>().pipe();
  }

  public getTimeZoneTypes(): Observable<any> {
    const appSettings: AppSetting = this.configPrv.AppSetting;
    this.baseApiService.set(appSettings.uriConfig.getTimeZoneTypes);
    return this.baseApiService.get<TimeZones[]>().pipe();
  }
  public validateRegistrationLink(
    token: RegistrationToken
  ): Observable<IRegistrationLinkValidationResponse> {
    const appSettings: AppSetting = this.configPrv.AppSetting;
    this.baseApiService.set(appSettings.uriAdministration.validateRegistration);
    return this.baseApiService.get<IRegistrationLinkValidationResponse>({
      params: { fromObject: { token: token.token } },
    });
  }

  public registerUser(request: UpdateUserRequest): Observable<any> {
    const appSettings: AppSetting = this.configPrv.AppSetting;
    this.baseApiService.set(appSettings.uriAdministration.registerUser);
    return this.baseApiService.post<UpdateUserResponse>(request).pipe();
  }

  public getCountries(): Observable<IConfigCountry[]> {
    const appSettings: AppSetting = this.configPrv.AppSetting;
    this.baseApiService.set(appSettings.uriConfig.getCountries);
    return this.baseApiService.get<IConfigCountry[]>();
  }

  public getAccount(): Observable<IAccount> {
    const appSettings: AppSetting = this.configPrv.AppSetting;
    this.baseApiService.set(appSettings.uriMarketplacePartners.getAccount);
    return this.baseApiService.get<IAccountResponse>().pipe(
      map((accountResponse) => {
        return {
          ...accountResponse.account,
          details: accountResponse.account.details[0],
        };
      })
    );
  }
}
