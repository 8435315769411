import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { map, take, tap } from 'rxjs/operators';

import {
  DateFormat,
  Languages,
  TimeZones,
} from '@lbmx/administration/lib/dtos/updateuser.dto';
import { IAccount } from '@lbmx/marketplace-partners/lib/models/account.models';
import { LoggerService } from '@lbmx/phoenix-lib-core';
import { SpinnerService, ValidationService } from '@lbmx/phoenix-lib-utils';
import * as i18nIsoCountries from 'i18n-iso-countries';
import { CountryCode, parsePhoneNumber } from 'libphonenumber-js';
import { Subscription } from 'rxjs';
import {
  IConfigCountry,
  RegistrationToken,
  UpdateUserRequest,
} from 'src/app/dtos/registration/registration.dto';
import { RegistrationService } from 'src/app/services/registration/registration.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit, AfterViewChecked, OnDestroy {
  public validToken = false;
  public isRegistered = false;
  public passwordMatch = true;
  public isDefault = true;
  public userKey = 0;
  public FirstName;
  public LastName;
  public accountKey: number;
  public passwordEmpty = true;
  public defaultCountryCode = 'CAN';
  public countries = [];
  public phoneExtension = {
    phoneExtension: () => 'Extension must be digits (maximum of 10 digits)',
  };
  public languageTypes: Languages[] = [];
  public timeZoneTypes: TimeZones[];
  public dateFormatTypes: DateFormat[] = [];
  public subscriptions: Subscription[] = [];
  public message = {
    confirmPassword: () =>
      this.translocoService.translate('COMMON.PASSWORD_MUST_MATCH'),
  };

  public registrationForm: FormGroup = this.formBuilder.group(
    {
      FirstName: ['', [Validators.required]],
      LastName: ['', [Validators.required]],
      UserName: ['', [Validators.required]],
      Password: [
        '',
        Validators.compose([
          Validators.required,
          this.validationService.patternValidator('number'),
          this.validationService.patternValidator('upperCase'),
          this.validationService.patternValidator('lowerCase'),
          this.validationService.specialCharacterValidator(),
          this.validationService.minimumLength(8),
        ]),
      ],
      ConfirmPassword: ['', [Validators.required]],
      Language: ['', [Validators.required]],
      TimeZone: ['', [Validators.required]],
      DateFormat: ['', [Validators.required]],
    },
    {
      validators: this.validationService.MustMatch(
        'Password',
        'ConfirmPassword'
      ),
    }
  );

  public phoneForm = this.formBuilder.group(
    {
      PhoneCountry: [''],
      PhoneExtension: [
        '',
        Validators.compose([
          Validators.pattern('^[0-9]*$'),
          Validators.maxLength(10),
        ]),
      ],
      Phone: [
        '',
        Validators.compose([
          Validators.required,
          this.validatePhoneNumber(parsePhoneNumber),
        ]),
      ],
    },
    {
      validators: this.validatePhoneForm('Phone', 'PhoneExtension'),
    }
  );

  constructor(
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private spinnerSrv: SpinnerService,
    private registrationSrv: RegistrationService,
    private loggerSrv: LoggerService,
    private validationService: ValidationService,
    private changeDetector: ChangeDetectorRef,
    private translocoService: TranslocoService
  ) {}

  public ngOnInit() {
    this.spinnerSrv.on();
    this.getCountries();
    this.validateToken();
  }

  public ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  public ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  public validateToken(): void {
    this.defaultCountryCode = 'CAN';
    this.phoneForm.controls.PhoneCountry.setValue(
      i18nIsoCountries.alpha3ToAlpha2(this.defaultCountryCode || 'CAN')
    );
    this.subscriptions.push(
      this.registrationSrv
        .validateRegistrationLink(this.buildValidateTokenRequest())
        .pipe(take(1))
        .subscribe(
          async (res) => {
            if (res !== null) {
              this.loggerSrv.info(
                'registerTokenResponse',
                'RegistrationService.validateRegistrationLink',
                JSON.stringify(res)
              );
              this.defaultCountryCode = res.accountCountry;
              this.phoneForm.controls.PhoneCountry.setValue(
                i18nIsoCountries.alpha3ToAlpha2(
                  this.defaultCountryCode || 'CAN'
                )
              );
              this.userKey = res.userInfo.UserKey;
              this.registrationForm.controls.UserName.setValue(
                res.userInfo.UserName
              );
              this.registrationForm.controls.FirstName.setValue(
                res.userInfo.FirstName
              );
              this.registrationForm.controls.LastName.setValue(
                res.userInfo.LastName
              );
              this.accountKey = Number(res.userInfo.AccountKey);
              this.languageTypes = res.languageTypes;
              this.timeZoneTypes = res.timezoneTypes;
              this.dateFormatTypes = res.dateTypes;
              this.validToken = true;
            }
          },
          (err) => {
            this.loggerSrv.error(
              'Error register token',
              'RegistrationService.validateRegistrationLink',
              err
            );
            this.validToken = false;
            this.isDefault = false;
          }
        )
        .add(() => {
          this.spinnerSrv.off();
        })
    );
  }

  private getCountries() {
    this.subscriptions.push(
      this.registrationSrv
        .getCountries()
        .pipe(
          map((countries: IConfigCountry[]) =>
            countries?.map((country) => {
              return {
                label: `${
                  country?.countryName
                } (${i18nIsoCountries.alpha3ToAlpha2(country?.countryCode)})`,
                value: i18nIsoCountries.alpha3ToAlpha2(country?.countryCode),
              };
            })
          ),
          tap((countries) => (this.countries = countries))
        )
        .subscribe()
    );
  }

  public submit(): void {
    this.spinnerSrv.on();
    if (
      this.registrationForm.value['Password'] ===
      this.registrationForm.value['ConfirmPassword']
    ) {
      this.subscriptions.push(
        this.registrationSrv
          .registerUser(this.buildRegisterUserRequest())
          .subscribe((res) => {
            this.isRegistered = true;
            this.loggerSrv.error(
              'Registration Success',
              'RegistrationService.registerUserProfile',
              res
            );
          })
          .add(() => {
            this.spinnerSrv.off();
          })
      );
    } else {
      this.passwordMatch = false;
    }
  }

  public passwordFieldEmptyCheck(passwordStatus) {
    this.passwordEmpty = passwordStatus ? false : true;
  }

  //#region  Helpers
  private buildValidateTokenRequest(): RegistrationToken {
    const request: RegistrationToken = new RegistrationToken();
    this.subscriptions.push(
      this.activatedRoute.queryParams.subscribe((params) => {
        request.token = params.token;
      })
    );

    return request;
  }

  private buildRegisterUserRequest(): UpdateUserRequest {
    const request: UpdateUserRequest = new UpdateUserRequest();
    try {
      request.phoneCountry = i18nIsoCountries.alpha2ToAlpha3(
        this.phoneForm?.controls?.PhoneCountry?.value
      );
      request.phoneExtension = this.phoneForm?.value?.PhoneExtension;
      request.phone = parsePhoneNumber(
        this.phoneForm?.controls?.Phone?.value,
        i18nIsoCountries.alpha3ToAlpha2(
          this.phoneForm?.controls?.PhoneCountry?.value
        ) as CountryCode
      ).format('E.164');
    } catch {
      request.phoneCountry = this.defaultCountryCode || 'CAN';
      request.phone = '';
      request.phoneExtension = '';
    }
    request.userName = this.registrationForm.value.UserName;
    request.accountKey = this.accountKey;
    request.firstName = this.registrationForm.value.FirstName;
    request.lastName = this.registrationForm.value.LastName;
    request.userKey = this.userKey;
    request.password = this.registrationForm.value.Password;
    request.timeZoneCode = this.registrationForm.value.TimeZone.timeZoneCode;
    request.languageCode = this.registrationForm.value.Language.languageCode;
    request.dateType = this.registrationForm.value.DateFormat.dateTypeKey;
    return request;
  }
  //#endregion

  // bound to Phone control's input event
  public formatPhoneNumber() {
    try {
      if (this.phoneForm?.controls?.Phone?.value?.length < 1) {
        this.phoneForm.controls.PhoneCountry.setValue(
          i18nIsoCountries.alpha3ToAlpha2(this.defaultCountryCode || 'CAN')
        );
      }
      this.phoneForm?.controls?.Phone?.setValue(
        parsePhoneNumber(
          this.phoneForm.controls.Phone.value,
          this.phoneForm.controls?.PhoneCountry?.value
        ).formatInternational()
      );
    } catch {
      /*empty*/
    }
  }

  public resetPhoneNumber() {
    this.phoneForm?.controls?.Phone?.setValue('');
  }

  public validatePhoneForm(
    phoneNumberControlName: string,
    ExtensionControlName: string
  ): ValidatorFn {
    return (formGroup: FormGroup) => {
      try {
        const phoneNumberControl = formGroup.controls[phoneNumberControlName];
        const extensionControl = formGroup.controls[ExtensionControlName];
        return extensionControl &&
          extensionControl.value &&
          extensionControl.value.length > 0 &&
          phoneNumberControl.value.length === 0
          ? { invalidPhoneNumber: true }
          : null;
      } catch (error) {
        return error;
      }
    };
  }

  public validatePhoneNumber(
    parser: (text: string) => { country?: any; isValid(): boolean }
  ): ValidatorFn {
    return (control: AbstractControl) => {
      try {
        const phoneNumber = parser(control.value.toString());
        return phoneNumber?.isValid() &&
          phoneNumber?.country ===
            `${this.phoneForm?.controls?.PhoneCountry?.value}`
          ? null
          : {
              invalidPhoneNumber: true,
            };
      } catch {
        return control?.value?.length < 1
          ? null
          : {
              invalidPhoneNumber: true,
            };
      }
    };
  }
}
