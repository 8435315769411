export default [
  {
    label: 'DOCUMENTS',
    routerLink: '/MarketplaceModule',
    routerLinkActiveOptions: {
      exact: false,
    },
    disabled: true,
    styleClass: 'uppercase',
    items: [
      {
        items: [
          {
            label: 'INVOICES',
            routerLink: '/MarketplaceModule/invoices',
            disabled: true,
          },
          {
            label: 'PURCHASE_ORDER',
            routerLink: '/MarketplaceModule/purchase_order',
            disabled: true,
          },
          {
            label: 'POAS_RECEIVED',
            routerLink: '/MarketplaceModule/poas_received',
            disabled: true,
          },
          {
            label: 'ASNS_RECEIVED',
            routerLink: '/MarketplaceModule/asns_received',
            disabled: true,
          },
          {
            label: 'CLAIMS_RECEIVED',
            routerLink: '/MarketplaceModule/claims_received',
            disabled: true,
          },
          {
            label: 'REMITTANCES_RECEIVED',
            routerLink: '/MarketplaceModule/remittances_received',
            disabled: true,
          },
        ],
      },
    ],
  },
  {
    label: 'PIM',
    routerLink: '/PIMModule',
    routerLinkActiveOptions: {
      exact: false,
    },
    disabled: true,
    styleClass: 'uppercase',
    items: [
      {
        label: 'CONTROL_CENTRE',
        items: [
          {
            label: 'CONTROL_CENTRE',
            routerLink: '/PIMModule/analytics',
          },
        ],
      },
      {
        label: 'PRODUCTS',
        routerLink: '/PIMModule/products',
        disabled: true,
        routerLinkActiveOptions: {
          exact: false,
        },
        items: [
          {
            label: 'APPROVED_PRODUCT',
            routerLink: '/PIMModule/products/approved-products',
          },
          {
            label: 'PENDING_PRODUCT',
            routerLink: '/PIMModule/products/products-pending',
          },
          {
            label: 'PRODUCT_FIELD',
            routerLink: '/PIMModule/products/product-fields',
          },
          {
            label: 'UPDATE_PROFILE',
            routerLink: '/PIMModule/products/inbound-update-profile',
          },
          {
            label: 'OUTBOUND_UPDATE_PROFILE',
            routerLink: '/PIMModule/products/outbound-update-profile',
          },
        ],
      },
      {
        label: 'IMPORT',
        items: [
          {
            label: 'IMPORT_DATA',
            routerLink: '/PIMModule/imports/import-products',
          },
          {
            label: 'IMPORT_ASSETS',
            routerLink: '/PIMModule/imports/import-assets',
          },
        ],
        disabled: true,
      },
      {
        label: 'EXPORT',
        disabled: true,
        items: [
          {
            label: 'VIEW_EXPORTS',
            routerLink: '/PIMModule/exports/view-exports',
          },
        ],
      },
    ],
  },
  {
    label: 'PARTNERS',
    routerLink: '/MarketplacePartnersModule',
    routerLinkActiveOptions: {
      exact: false,
    },
    disabled: true,
    styleClass: 'uppercase',
    items: [
      {
        items: [
          {
            label: 'COMPANY',
            routerLink: '/MarketplacePartnersModule/company',
            disabled: true,
          },
          {
            label: 'MY_MARKETPLACE',
            routerLink: '/MarketplacePartnersModule/my_marketplace',
            disabled: true,
          },
          {
            label: 'SUPPLIER',
            routerLink: '/MarketplacePartnersModule/supplier',
            disabled: true,
          },
          {
            label: 'MEMBER',
            routerLink: '/MarketplacePartnersModule/member',
            disabled: true,
          },
          {
            label: 'REQUESTS',
            routerLink: '/MarketplacePartnersModule/requests',
            disabled: true,
          },
        ],
      },
    ],
  },
  {
    icon: 'pi pi-fw pi-cog',
    routerLink: '/AdministrationModule',
    routerLinkActiveOptions: {
      exact: false,
    },
    disabled: true,
    styleClass: 'uppercase',
    items: [],
  },
  {
    icon: 'pi pi-fw pi-user',
    routerLinkActiveOptions: {
      exact: false,
    },
    disabled: true,
    styleClass: 'uppercase',
    items: [
      {
        items: [
          {
            label: 'PROFILE',
            routerLink: '/userProfile',
            disabled: true,
          },
          {
            label: 'SWITCH_MARKETPLACES',
            disabled: true,
          },
          {
            label: 'LOG_OUT',
            routerLink: '/logout',
            disabled: true,
          },
        ],
      },
    ],
  },
];
