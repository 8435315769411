import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslocoService } from '@ngneat/transloco';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-public-layout-component',
  templateUrl: './public.component.html',
})
export class PublicComponent implements OnInit, OnDestroy {
  //#region Properties

  public loggedUserFullName = '';
  public subscription: Subscription;

  //#endregion

  constructor(
    private titleService: Title,
    private translocoService: TranslocoService
  ) {
    /** empty block */
  }

  public ngOnInit() {
    this.setTitle();
    /** empty block */
  }
  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  public setTitle() {
    this.subscription = this.translocoService
      .selectTranslate('COMMON')
      .pipe(
        tap((translate) => {
          this.titleService.setTitle('LBMX ' + translate.MARKETPLACE);
        })
      )
      .subscribe();
  }
}
