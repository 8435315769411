import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ValidationService } from '@lbmx/phoenix-lib-utils';

import { TranslocoService } from '@ngneat/transloco';
import * as i18nIsoCountries from 'i18n-iso-countries';
import { AsYouType, CountryCode, parsePhoneNumber } from 'libphonenumber-js';
import { IUserProfileForm } from 'src/app/dtos/userProfile/userProfile.dto';
@Component({
  selector: 'app-userprofile',
  templateUrl: './userprofile.component.html',
  styleUrls: ['./userprofile.component.scss'],
})
export class UserprofileComponent {
  @Input('defaultCountryCode') set defaultCountryCodeSetter(
    defaultCountryCode: string
  ) {
    this.defaultCountryCode = defaultCountryCode;
  }
  @Input('countries') set countriesSetter(countries: any[]) {
    this.countries = countries;
  }
  @Input('userProfileDetails') set userProfileDetailsSetter(
    userProfileDetails: IUserProfileForm
  ) {
    this.userProfileDetails = userProfileDetails;
    this.initializeForm();
    this.formatPhoneToInternational();
  }
  @Input() public DTFormat;
  @Input() public fetching = false;
  @Input() public languageTypes;
  @Input() public timeZoneTypes;
  @Output() public updatedProfileInfo = new EventEmitter<any>();
  public userProfileForm: FormGroup;
  public phoneForm: FormGroup;
  public showPasswordEdit = false;
  public passwordEmpty = true;
  public countries = [];
  public defaultCountryCode = 'CAN';
  public message = {
    confirmPassword: () =>
      this.translocoService.translate('COMMON.PASSWORD_MUST_MATCH'),
  };
  public extension = {
    extension: () => 'Extension must be digits (maximum of 10 digits)',
  };
  public userProfileDetails: IUserProfileForm;
  public phoneNumber = new AsYouType();

  constructor(
    private formBuilder: FormBuilder,
    private validationService: ValidationService,
    private translocoService: TranslocoService
  ) {}

  /*Handling Password Checkbox  Click */
  public handleUpdatePassword(status) {
    this.showPasswordEdit =
      status.checked && status.checked === true ? true : false;
  }

  private initializeForm() {
    const userProfileDetails: IUserProfileForm =
      this.userProfileDetails ||
      ({
        firstName: '',
        lastName: '',
        userName: '',
        phoneCountry: 'CA',
        phone: '',
        phoneExtension: '',
        languageCode: '',
        timeZoneCode: '',
        dateTypeKey: 1,
      } as IUserProfileForm);
    this.userProfileForm = this.formBuilder.group(
      {
        firstName: [userProfileDetails.firstName, [Validators.required]],
        lastName: [userProfileDetails.lastName, [Validators.required]],
        userName: [
          userProfileDetails.userName,
          [Validators.required, Validators.email],
        ],
        password: [
          '',
          Validators.compose([
            this.validationService.patternValidator('number'),
            this.validationService.patternValidator('upperCase'),
            this.validationService.patternValidator('lowerCase'),
            this.validationService.specialCharacterValidator(),
            this.validationService.minimumLength(8),
          ]),
        ],
        confirmPassword: [''],
        languageCode: [userProfileDetails.languageCode, [Validators.required]],
        timeZoneCode: [userProfileDetails.timeZoneCode, [Validators.required]],
        dateTypeKey: [userProfileDetails.dateTypeKey, [Validators.required]],
      },
      {
        validator: this.validationService.MustMatch(
          'password',
          'confirmPassword'
        ),
      }
    );
    this.phoneForm = this.formBuilder.group(
      {
        phoneCountry: [this.formatCountryCodeForDropdown()],
        phoneExtension: [
          `${userProfileDetails?.phoneExtension}`,
          Validators.compose([
            Validators.pattern('^[0-9]*$'),
            Validators.maxLength(10),
          ]),
        ],
        phone: [
          `${userProfileDetails?.phone}`,
          Validators.compose([
            Validators.required,
            this.validatePhoneNumber(parsePhoneNumber),
          ]),
        ],
      },
      {
        validators: this.validatePhoneForm('phone', 'phoneExtension'),
      }
    );
  }

  public passwordFieldEmptyCheck(passwordStatus) {
    this.passwordEmpty = passwordStatus ? false : true;
  }

  /*Handling Save Button Click */
  public handleSubmit() {
    try {
      this.updatedProfileInfo.emit({
        ...this.userProfileForm.value,
        ...this.phoneForm.value,
        phoneCountry: i18nIsoCountries.alpha2ToAlpha3(
          this.phoneForm.controls.phoneCountry.value || 'CA'
        ),
        phone: parsePhoneNumber(
          this.phoneForm.controls.phone.value,
          this.phoneForm.controls.phoneCountry.value as CountryCode
        ).format('E.164'),
      });
    } catch {
      this.updatedProfileInfo.emit({
        ...this.userProfileForm.value,
        ...this.phoneForm.value,
        phoneCountry: this.defaultCountryCode || 'CAN',
        phone: '',
        phoneExtension: '',
      });
    }
  }

  // bound to Phone control's input event
  public formatPhoneNumber() {
    try {
      if (this.phoneForm.controls.phone.value.length < 1) {
        this.phoneForm.controls.phoneCountry.setValue(
          i18nIsoCountries.alpha3ToAlpha2(this.defaultCountryCode || 'CAN')
        );
      }
      this.phoneForm.controls.phone.setValue(
        parsePhoneNumber(
          this.phoneForm.controls.phone.value,
          this.phoneForm.controls.phoneCountry.value
        ).formatInternational()
      );
    } catch {
      /*empty*/
    }
  }

  // bound to Countries Dropdown control's onChange event
  public resetPhoneNumber() {
    this.phoneForm?.controls?.phone?.setValue('');
  }

  // sets country code from 3 to 2 numbers
  private formatCountryCodeForDropdown() {
    try {
      return i18nIsoCountries.alpha3ToAlpha2(
        this.userProfileDetails.phoneCountry.trim()
      );
    } catch {
      return i18nIsoCountries.alpha3ToAlpha2(this.defaultCountryCode || 'CAN');
    }
  }

  // helper function for userProfileDetailsSetter
  private formatPhoneToInternational() {
    try {
      this.phoneNumber.input(this.userProfileDetails.phone);

      this.phoneForm.controls.phone.setValue(
        this.phoneNumber.getNumber().formatInternational()
      );
    } catch {
      this.phoneForm.controls.phone.setValue(
        this.userProfileDetails ? this.userProfileDetails.phone : ''
      );
    }
  }

  public validatePhoneForm(
    phoneNumberControlName: string,
    ExtensionControlName: string
  ): ValidatorFn {
    return (formGroup: FormGroup) => {
      try {
        const phoneNumberControl = formGroup.controls[phoneNumberControlName];
        const extensionControl = formGroup.controls[ExtensionControlName];
        return extensionControl &&
          extensionControl.value &&
          extensionControl.value.length > 0 &&
          phoneNumberControl.value.length === 0
          ? { invalidPhoneNumber: true }
          : null;
      } catch (error) {
        return error;
      }
    };
  }

  public validatePhoneNumber(
    parser: (text: string) => { country?: any; isValid(): boolean }
  ): ValidatorFn {
    return (control: AbstractControl) => {
      try {
        const phoneNumber = parser(control.value.toString());
        return phoneNumber?.isValid() &&
          phoneNumber?.country ===
            `${this.phoneForm?.controls?.phoneCountry?.value}`
          ? null
          : {
              invalidPhoneNumber: true,
            };
      } catch {
        return control?.value?.length < 1
          ? null
          : {
              invalidPhoneNumber: true,
            };
      }
    };
  }
}
