import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { actions, selectors } from '@lbmx/app-state';
import { NavItem, UserProfile } from '@lbmx/types';
import { TranslocoService } from '@ngneat/transloco';
import { select, Store } from '@ngrx/store';
import { combineLatest, interval, Subscription } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';

import { ConfigProvider } from 'src/app/provider/config-provider';
import disabledNavItems from './nav-Items';
import { routePermissions } from './permissions';
import { SecureService } from './secure.service';
import { sessionExpired } from './utils';

@Component({
  selector: 'app-secure-layout-component',
  templateUrl: './secure.component.html',
})
export class SecureComponent implements OnInit, AfterContentChecked, OnDestroy {
  //#region Properties
  private sessionTimer = interval(1000);
  private tokenExpires: Date;
  private tokenIssued: Date;
  public loggedUserFullName = '';
  public ready = false;
  public showMarketplaceModal: number;
  public subscriptions: Subscription[] = [];
  //#endregion

  constructor(
    private configPrv: ConfigProvider,
    private store: Store,
    private translocoService: TranslocoService,
    private titleService: Title,
    private router: Router,
    public secureService: SecureService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.configStore();
  }

  public ngOnInit() {
    this.subscriptions.push(
      this.store
        .pipe(
          select(selectors.userProfile),
          tap((user: UserProfile) => {
            this.translocoService.setActiveLang(
              user.userInfo.LanguageCode ? user.userInfo.LanguageCode : 'en-ca'
            ); // when logging out, it will change to en-ca automatically
            this.setTitle();
          })
        )
        .subscribe()
    );

    this.subscriptions.push(
      this.store
        .pipe(
          select(selectors.session),
          tap(
            (session) => (
              (this.tokenExpires = new Date(session.tokenExpires)),
              (this.tokenIssued = new Date(session.tokenIssued))
            )
          )
        )
        .subscribe()
    );

    this.subscriptions.push(
      this.sessionTimer
        .pipe(map(() => sessionExpired(this.tokenIssued, this.tokenExpires)))
        .subscribe((expired) => {
          if (expired) {
            this.router.navigate(['logout']);
          }
        })
    );

    this.subscriptions.push(
      combineLatest([
        this.store.pipe(
          select(selectors.authorizeRecursively, {
            permissionQuery: routePermissions,
            itemList: disabledNavItems,
            transformer: (
              item: NavItem,
              authorizedNavItems,
              allowSwitchMarketplace
            ) =>
              Object.assign(
                {},
                {
                  disabled: this.unauthorizedItem(authorizedNavItems, item),
                },
                item.label === 'SWITCH_MARKETPLACES' && {
                  command: () => this.switchMarketplace(),
                },
                item.label === 'SWITCH_MARKETPLACES' &&
                  !allowSwitchMarketplace && {
                    visible: false,
                  },
                this.unauthorizedItem(authorizedNavItems, item) && {
                  routerLink: '',
                }
              ),
            recursiveProp: 'items',
          })
        ),
        this.store.pipe(select(selectors.marketplaces)),
      ])
        .pipe(
          first(([, marketplaces]) => marketplaces.marketplaces !== null),
          map(([navItems, ..._]) => navItems),
          tap((navItems: NavItem[]) => {
            this.store.dispatch(
              actions.setNavItems({
                navItems,
              })
            );
            this.ready = true;
          })
        )
        .subscribe()
    );
  }
  public ngAfterContentChecked() {
    this.changeDetectorRef.detectChanges();
  }

  public refreshUserProfile() {
    return () =>
      this.store.dispatch(
        actions.refreshUserProfile({
          endPoints: this.configPrv.AppSetting,
        })
      );
  }

  public setTitle() {
    this.subscriptions.push(
      this.translocoService
        .selectTranslate('COMMON')
        .pipe(
          tap((translate) => {
            this.titleService.setTitle('LBMX ' + translate.MARKETPLACE);
          })
        )
        .subscribe()
    );
  }

  public ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  private configStore() {
    const { uriProduct, uriSecurity, uriAdministration } =
      this.configPrv.AppSetting;
    this.store.dispatch(
      actions.loadUserProfile({ endPoints: this.configPrv.AppSetting })
    );
    this.store.dispatch(
      actions.getMarketplaces({ endPoints: this.configPrv.AppSetting })
    );

    this.store.dispatch(actions.loadCategories({ endPoints: uriProduct }));
    this.store.dispatch(actions.loadProductFields({ endPoints: uriProduct }));
    this.store.dispatch(actions.loadUsers({ endPoints: uriAdministration }));
  }

  private urlToPermissionKey(url: string): string {
    const paths = url.split('/').filter((x) => x !== '');
    return paths.filter((path) => isNaN(parseInt(path, 10))).join('_');
  }

  private unauthorizedItem(
    authorizedNavItems: { [key: string]: boolean },
    item: NavItem
  ) {
    return item.routerLink
      ? !authorizedNavItems[this.urlToPermissionKey(item.routerLink)]
      : false;
  }
  public switchMarketplace() {
    this.showMarketplaceModal = Date.now();
  }
}
