import { Component, Input, OnInit } from '@angular/core';
import { NavItem } from '@lbmx/types';
import { TranslocoService } from '@ngneat/transloco';
import { MegaMenuItem } from 'primeng';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
interface INavItemWithId extends NavItem {
  id?: string;
}
@Component({
  selector: 'app-main-nav-bar',
  templateUrl: './main-nav-bar.component.html',
  styleUrls: ['./main-nav-bar.component.scss'],
})
export class MainNavBarComponent implements OnInit {
  @Input() public navigationItems$: Observable<NavItem[]>;
  public navItemsToDisplay$: Observable<MegaMenuItem[]>;

  constructor(private translocoService: TranslocoService) {
    /*empty block */
  }

  public ngOnInit() {
    this.navItemsToDisplay$ = combineLatest([
      this.navigationItems$,
      this.translocoService.selectTranslate('NAV'),
    ]).pipe(
      map(([navigationItems, translation]) =>
        this.translateLabels(navigationItems, translation)
      ),
      map((navigationItems) => this.transform(navigationItems))
    );
  }

  private translateLabels(navigationItems: INavItemWithId[], translation) {
    return navigationItems.map((navigationItem: INavItemWithId) =>
      Object.assign(
        {},
        this.addId(navigationItem),
        { label: translation[navigationItem.label] },
        navigationItem['items'] && {
          ['items']: this.translateLabels(navigationItem['items'], translation),
        }
      )
    );
  }

  private transform(navigationItems: INavItemWithId[]): MegaMenuItem[] {
    return navigationItems.map((item: INavItemWithId) => {
      return {
        ...item,
        items: item.items.map((i) => [i]),
      };
    });
  }

  private addId(item: NavItem): INavItemWithId {
    return { id: this.automationId(item), ...item };
  }

  private automationId(item: NavItem): string | undefined {
    const routerLinkId = item.routerLink
      ? item.routerLink
          .split('/')
          .filter((i) => i !== '')
          .join('_')
      : item.routerLink;
    return item.label || routerLinkId;
  }
}
